<template>
  <div class="how_item">
    <div class="inner">
      <h5>3 Easy Steps to Get Cash Loans</h5>
      <ul class="how_ul">
        <li>
          <!-- <img src="@/assets/images/loanImg/icon_apply.png" alt=""> -->
          <p class="p1">Choose loan products</p>
          <p class="p2">Choose what you need from<br> many loan products.</p>
        </li>
        <li>
          <!-- <img src="@/assets/images/loanImg/-s-icon_sign.png" alt=""> -->
          <p class="p1">E-sign</p>
          <p class="p2">Sign the loan agreement via <br>digital signature</p>
        </li>
        <li>
          <!-- <img src="@/assets/images/loanImg/icon_approved.png" alt=""> -->
          <p class="p1">Get approved & Get money</p>
          <p class="p2">Get a decision from in<br> BilletePlus 1 to 30 minutes and Receive your<br> loan in your bank account.</p>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.how_item{
  .inner{
    width: 1200px;
    margin: 40px auto;
    h5{
      font-size: 30px;
      color: #333333;
      margin-bottom: 70px;
      text-align: center;
    }
    .how_ul{
      padding-bottom: 50px;
      overflow: hidden;
      li{
        float: left;
        width: 33.3%;
        text-align: center;
        img{
          width: 140px;
          // height: 143px;
          margin-bottom: 30px;
        }
        .p1{
          font-size: 22px;
          color: #333333;
          margin-bottom: 15px;
        }
        .p2{
          font-size: 16px;
          color: #999999;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
